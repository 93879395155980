<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Grupo" : "Cadastrar Grupo" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Grupo</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">Nome</span>
              <v-text-field
                v-model="dados.nome"
                placeholder="Informe o nome"
                class="mt-n1"
                :rules="fieldRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Breve Descrição *</span>
              <v-text-field
                class="mt-n1"
                v-model="dados.descricao"
                placeholder="Informe uma breve descrição"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <v-card class="mb-15">
      <v-card-title class="primary white--text d-flex justify-space-between">
        <span>Permissões do Grupo</span>
      </v-card-title>

      <v-card-text class="pt-7 px-7">
        <v-row justify="center">
          <v-expansion-panels focusable flat accordion>
            <v-expansion-panel>
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Ajuda:
                  {{ permissoes.ajuda && permissoes.ajuda.length }}
                  Ajuda</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.ajuda"
                  v-for="(a, i) in descricaoPermissoes.ajuda"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Cadastros: {{ permissoes.cadastros.length }} Permissões
                  Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.cadastros"
                  v-for="(a, i) in descricaoPermissoes.cadastros"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Agências: {{ permissoes.agencias.length }} Permissões
                  Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.agencias"
                  v-for="(a, i) in descricaoPermissoes.agencias"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Cad. Operacionais:
                  {{ permissoes.cadOperacionais.length }} Permissões Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.cadOperacionais"
                  v-for="(a, i) in descricaoPermissoes.cadOperacionais"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b>Preço: {{ permissoes.preco.length }} Permissões Ativas</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.preco"
                  v-for="(a, i) in descricaoPermissoes.preco"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Financeiro: {{ permissoes.financeiro.length }} Permissões
                  Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.financeiro"
                  v-for="(a, i) in descricaoPermissoes.financeiro"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Parâmetros: {{ permissoes.parametros.length }} Permissões
                  Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.parametros"
                  v-for="(a, i) in descricaoPermissoes.parametros"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Viagens: {{ permissoes.viagens.length }} Permissões Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.viagens"
                  v-for="(a, i) in descricaoPermissoes.viagens"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Passagens: {{ permissoes.passagens.length }} Permissões
                  Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.passagens"
                  v-for="(a, i) in descricaoPermissoes.passagens"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b>Caixa: {{ permissoes.caixa.length }} Permissões Ativas</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.caixa"
                  v-for="(a, i) in descricaoPermissoes.caixa"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b>Vendas: {{ permissoes.vendas.length }} Permissões Ativas</b>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.vendas"
                  v-for="(a, i) in descricaoPermissoes.vendas"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Voucher: {{ permissoes.voucher.length }} Permissões Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.voucher"
                  v-for="(a, i) in descricaoPermissoes.voucher"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Encomendas: {{ permissoes.encomendas.length }} Permissões
                  Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.encomendas"
                  v-for="(a, i) in descricaoPermissoes.encomendas"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: 1px solid #ddd">
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Escalas: {{ permissoes.escalas.length }} Permissões Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.escalas"
                  v-for="(a, i) in descricaoPermissoes.escalas"
                  :key="i"
                  :label="a.desc"
                  :value="a.name"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="title font-weight-light">
                <b
                  >Geral:
                  {{
                    permissoes.pdv.length +
                    permissoes.dashboard.length +
                    permissoes.reportbpe.length
                  }}
                  Permissões Ativas</b
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mt-5">
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.pdv"
                  label="Realizar Vendas"
                  value="PDV"
                ></v-switch>
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.dashboard"
                  label="Acesso ao painel de controle do sistema (Dashboard)"
                  value="Dashboard"
                ></v-switch>
                <v-switch
                  color="green darken-1"
                  class="pa-0 mb-0 mt-0"
                  inset
                  dense
                  v-model="permissoes.reportbpe"
                  label="Emitir relatório do bpe"
                  value="RBPE"
                ></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>

      <!-- {{ permissoes }} -->
    </v-card>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-footer color="primary" class="rounded-t footer-form" absolute>
      <v-spacer></v-spacer>
      <v-btn text depressed dark :disabled="!valid" @click="validate"
        >Confirmar</v-btn
      >
    </v-footer>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data: () => ({
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    valid: true,
    dados: {
      nome: "",
      descricao: "",
      permissoes: [],
    },
    permissoes: {
      pdv: [],
      dashboard: [],
      cadastros: [],
      agencias: [],
      cadOperacionais: [],
      preco: [],
      financeiro: [],
      parametros: [],
      viagens: [],
      passagens: [],
      caixa: [],
      vendas: [],
      voucher: [],
      encomendas: [],
      escalas: [],
      ajuda: [],
      reportbpe: [],
    },
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 2000,
    },
    descricaoPermissoes: {
      cadastros: [
        { desc: "Cadastro de Clientes", name: "Clientes" },
        { desc: "Cadastro de Operadores", name: "Operadores" },
        {
          desc: "Cadastro de Formas de Pagamento",
          name: "Formas de Pagamento",
        },
        { desc: "Cadastro de Tipos de Venda", name: "Tipos de Venda" },
        { desc: "Cadastro de Tipos de Serviço", name: "Tipos de Servico" },
        { desc: "Cadastro de Orgão Regulador", name: "Orgão Regulador" },
        { desc: "Cadastro de Empresas", name: "Empresas" },
        { desc: "Cadastro de Motoristas", name: "Motoristas" },
        {
          desc: "Cadastro de Natureza de Operações",
          name: "Naturezas de Operações",
        },
      ],
      agencias: [
        { desc: "Cadastro de Agências", name: "Gerenciar Agências" },
        { desc: "Comissões", name: "Comissões" },
      ],
      encomendas: [
        { desc: "Cadastro de Encomendas", name: "Gerenciar Encomendas" },
        { desc: "Movimentar de Encomendas", name: "Movimentar Encomendas" },
        { desc: "Encomendas", name: "Encomendas" },
        { desc: "Clientes de Encomendas", name: "Clientes de Encomendas" },
        { desc: "Gerenciar Encomendas", name: "Gerenciar Encomendas" },
        { desc: "Encomenda CTE", name: "Encomenda CTE" },
        { desc: "Mapa de Encomendas", name: "Mapa de Encomendas" },
      ],
      escalas: [
        { desc: "Escala dos Ônibus", name: "Escala dos Ônibus" },
        { desc: "Escala dos Motoristas", name: "Escala dos Motoristas" },
      ],
      cadOperacionais: [
        { desc: "Cadastro de Localidadaes", name: "Localidadaes" },
        { desc: "Cadastro de Linhas", name: "Linhas" },
        { desc: "Cadastro de Ônibus", name: "Ônibus" },
        { desc: "Cadastro de Layouts", name: "Layouts" },
        { desc: "Escala de Horário", name: "Escala de Horário" },
        {
          desc: "Configurar de Reaproveitamento de Trechos",
          name: "Reaproveitamento de Trechos",
        },
        { desc: "Cadastro de Viagens", name: "Viagens" },
        { desc: "Gerar Gratuidades", name: "Gerar Gratuidades" },
        { desc: "Checagem de Trechos", name: "Checagem de Trechos" },
      ],
      preco: [
        { desc: "Cadastro de Tarifas", name: "Tarifas" },
        { desc: "Cadastro de Descontos", name: "Descontos" },
        { desc: "Cadastro de Gratuidades", name: "Gratuidades" },
      ],
      financeiro: [
        { desc: "Livro Caixa", name: "Livro Caixa" },
        { desc: "Transferências", name: "Transferências" },
        { desc: "Caixa Encomendas", name: "Caixa Encomendas" },
        {
          desc: "Caixa Encomendas Detalhados",
          name: "Caixa Encomendas Detalhados",
        },
        { desc: "Comissões", name: "Comissões" },
        { desc: "Comissões Encomendas", name: "Comissões Encomendas" },
        { desc: "Comissões Passagens", name: "Comissões Passagens" },
        { desc: "Lançamentos", name: "Lançamentos" },
        {
          desc: "Encomendas Pagas e Não Pagas",
          name: "Encomendas Pagas e Não Pagas",
        },
        { desc: "Relatório Sintético", name: "Relatório Sintético" },
        { desc: "Relatório Fechamento", name: "Relatório Fechamento" },
        { desc: "Bpe Emitidos", name: "Bpe Emitidos" },
        { desc: "Passagens Vendidas", name: "Passagens Vendidas" },
      ],
      parametros: [
        { desc: "Configurar Aliquotas", name: "Aliquotas" },
        { desc: "Configurações do BP-e", name: "Bp-e" },
        { desc: "Configurações do Ct-e", name: "Ct-e" },
        { desc: "Gerênciar Grupos", name: "Grupos e Permissões" },
      ],
      viagens: [
        { desc: "Confirmar Embarque", name: "Confirmar Embarque" },
        { desc: "Mapa de Viagem", name: "Mapa de Viagem" },
      ],
      passagens: [
        {
          desc: "Emissão do Excesso de Bagagem",
          name: "Excesso de Bagagem",
        },
        { desc: "Consultar Passagens", name: "Consultar" },
        {
          desc: "Gerenciar BPe-s com Erros",
          name: "Gerenciar BPe-s com Erros",
        },
        { desc: "Gerenciar BPe-s Pedentes", name: "Gerenciar BPe-s Pedentes" },
      ],
      caixa: [{ desc: "Caixa PDV", name: "Caixa" }],
      vendas: [{ desc: "Venda Manual", name: "Vendas" }],
      voucher: [{ desc: "Troca de Voucher", name: "Voucher" }],
      ajuda: [{ desc: "Vídeos", name: "Videos" }],
    },
  }),
  created() {
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/grupos/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.permissoes = {
            ...this.permissoes,
            ...JSON.parse(this.dados.permissoes),
          };
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning darken-1",
          text: "Atencão um ou mais campos precisam ser preenchidos!",
        });
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.dados.permissoes = JSON.stringify(this.permissoes);
      if (this.id) {
        this.$http
          .post(`/grupos/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "success darken-1",
                text: r.data.success,
              });
              setTimeout(() => {
                this.$router.push("/parametros/grupos");
              }, 500);
            } else {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "error darken-1",
                text: r.data.error,
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error darken-1",
              text: e,
            });
          });
      } else {
        this.$http
          .post("/grupos/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.$store.dispatch("activeLoader", false);
              this.$store.dispatch("showMessage", {
                show: true,
                color: "success darken-1",
                text: r.data.success,
              });
              setTimeout(() => {
                this.$router.push("/parametros/grupos");
              }, 1500);
            } else {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "error darken-1",
                text: r.data.error,
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error darken-1",
              text: e,
            });
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
